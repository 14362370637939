import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  constructor() { }
  
  getDataFromToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split(".")[1]));
    }
    return null;
  }

  isAuthenticated(): boolean {
    let payload = this.getDataFromToken(sessionStorage.getItem('aToken'));    
    if ( payload!=null && payload.user_id>0) {      
      return true;
    }
    return false;
  }  
}
