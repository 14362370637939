import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Perfiles } from '../models/perfiles';
import {Job} from '../models/job';

@Injectable({
  providedIn: 'root'
})
export class PerfilesService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  public urlEndPoint: String = 'https://backdemo.frenteurbano.org/api/admin';

  private addHttpHeaderToken() {
    let token = sessionStorage.getItem('aToken');
    if (token != null) {
      return this.httpHeaders.append('Authorization', 'Bearer ' + token);
    }
    return this.httpHeaders;
  }
  getAllConcursos(): Observable<any> {
    return this.http.get<any>('https://backdemo.frenteurbano.org/api/admin/concurso/cat/list/', { headers: this.addHttpHeaderToken() });
  }

  getAllPerfiles(perfiles:Perfiles): Observable<any> {
    return this.http.post<any>('https://backdemo.frenteurbano.org/api/admin/perfiles/list/',perfiles ,{ headers: this.addHttpHeaderToken() });
  }
  getExportar3(perfiles: Perfiles, filename: string = null) {
    this.http.post('https://backdemo.frenteurbano.org/api/admin/perfiles/list/export/', perfiles, { responseType: 'blob', headers: this.httpHeaders })
      .subscribe(response => this.downLoadFile(response, filename));

  }
  downLoadFile(data: any, filename: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  postJobPerfiles(job:Job): Observable<any> {
    return this.http.post<any>('https://backdemo.frenteurbano.org/api/admin/cargas/create/',job ,{ headers: this.addHttpHeaderToken() });
  }
  deleteLstPerfil(objPerfil:Perfiles) {
    return this.http.post<any>('https://backdemo.frenteurbano.org/api/admin/perfiles/delete/',objPerfil ,{ headers: this.addHttpHeaderToken() });

  }

}
