import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ganadores } from '../models/ganadores';
import { GanadoresSend } from '../models/ganadores-send';

@Injectable({
  providedIn: 'root'
})
export class GanadoresService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  private addHttpHeaderToken() {
    let token = sessionStorage.getItem('aToken');
    if (token != null) {
      return this.httpHeaders.append('Authorization', 'Bearer ' + token);
    }
    return this.httpHeaders;
  }
  constructor(private http: HttpClient) { }

  postGanadores(objGanadores: GanadoresSend) {
    /* objGanadores.concurso_id = '1';
    objGanadores.tipo = '0'; */
    return this.http.post<any>('https://backdemo.frenteurbano.org/api/admin/perfiles/ganadores/list/',objGanadores ,{ headers: this.addHttpHeaderToken() });
  }
}
